// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$AirDnaScraper-primary: mat.define-palette(mat.$indigo-palette);
$AirDnaScraper-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$AirDnaScraper-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$AirDnaScraper-theme: mat.define-light-theme(
  (
    color: (
      primary: $AirDnaScraper-primary,
      accent: $AirDnaScraper-accent,
      warn: $AirDnaScraper-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($AirDnaScraper-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

section {
  padding: 20px;
}

.input-field {
  @apply border border-gray-400 rounded-lg py-2 px-4 focus:outline-none 
    focus:ring-2 focus:ring-blue-600 focus:border-transparent w-full;

  &:not(:read-only) {
    &::placeholder {
      @apply text-gray-400;
    }

    &:hover {
      @apply border-blue-600;
    }

    &:focus {
      @apply bg-blue-50;
    }
  }

  &:read-only {
    @apply border-0;
    @apply bg-gray-200;
  }
}


.mdc-notched-outline__notch { border-right: none; }

.mat-mdc-form-field.small {
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-vertical-padding: 8px;
}

.rounded .mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 28px !important;
}


.link{
  @apply text-cyan-800 cursor-pointer hover:underline;
}